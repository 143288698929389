
footer{
    width: 100vw;
    height: 100%;
    background: #BD1716;
}
/*
@media (min-width: 576px){*/

.footer-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    align-items: center;
    padding: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    color:white;

}

.footer-section a{

        font-weight: 800;
        text-decoration: none;
        color: white;

    }
    
    .footer-section h4{
        font-size: 20px;

    }    
    
    .footer-section li{
        list-style-type: none;
        margin-top: 8px;

    }  

    .footer-section li a{
        display: block;
        color: white;
        font-size: 15px;
        font-weight: 400;
        transition: all .40s ease;

    }  
    
    .footer-section li a:hover{
        transform: translateY(-3px) translateX(-3px)  ;
        font-size: 15px;
        font-weight: 400;
        color: #F5A6A6;
        text-decoration: none;
        transition: all .40s ease;

    }  

    .footer-icons a{
        display: inline-block;
        font-size: 22px;
        color: white;
        margin-top: 16px;
        margin-right: 17px;
        transition: all .40s ease;
    }
    .footer-icons a:hover{
        color: wheat;
        transform: translate(-3px);

    }

    @media (min-width:768px){
        footer {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
  
   
 

  
