
*{
    margin: 0;
    padding: 0;
}
.gallery-section{
    position: relative;
    width: 100vw;
    background-color: #db7598;
    padding: 20px;
}
.gallery-title{
    text-align: center;
    padding: 10px;
}
.gallery-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.text-content{
    padding: 20px;
    text-align: center;
}
.see-all-btn{
    background-color: #ae2d59;
    color: white;
    border-radius: 2rem;
    border: 1px solid #cb376a;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    font-family: "Monserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
}
.see-all-btn:hover {
    background-color: #cb376a;
    border: 1px solid #db7598;
}

.gallery-content{
width: 60%;
text-align: center;
}
.gallery-content:hover{
background-color: white;
box-shadow: 5px 5px 15px  #7a1837;
cursor: pointer;
    }
.category-title{
        font-weight: 200;
        text-decoration: none;
        color: #ae2d59;
}


@media (min-width: 768px){
    .gallery-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }
    .text-content{
        width: 15%;
        text-align: right;
    }

    .gallery-content{
        width: 15%;
        text-align: center;
        }

}