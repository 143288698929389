/*.contact>*+*{
    margin: 1rem 1rem;
    padding: 1rem 1rem;
    border:1px solid green;
}*/

.contact{
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
}



@media (min-width: 768px) {
    .contact{
        display: flex;
        flex-direction: row;
    }
    
}