    
.homeServicesAdvance-wrapper.left{
    display: flex;
  background-color: green;
    flex-direction: column;
}
    
.homeServicesAdvance-wrapper.right{
    display: flex;
  background-color: yellow;
    flex-direction: column-reverse;
}
.homeServicesAdvance-img img{
    width: 100vw;
}
.homeServicesAdvance-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;    
    accent-color: #fff;
}

.homeServicesAdvance-text .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}

.homeServicesAdvance-text a{
    margin-top: 0.5rem;
    background-color: red;
    display: table-header-group;
    color:#fff;
    width: 15vw;
    border-radius: 15px;
    padding: 5px;
    text-align: center;
    text-decoration: none;
}

@media (min-width: 768px) {
    .homeServicesAdvance-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .homeServicesAdvance-text{
        height: initial;
        flex: 1;
    }

    .homeServicesAdvance-text .wrapper{
        /*width: initial;*/
    }

    
    .homeServicesAdvance-img img{
        width: 50vw;
    }
    .homeServicesAdvance-wrapper.left{
        display: flex;
        flex-direction: row;
    }
    .homeServicesAdvance-wrapper.right{
        display: flex;
        flex-direction: row-reverse;
    }
}