.registerSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 80vh;
}
.registerSection .wrapper{
    border: 1px solid aquamarine;
    border-radius: 1rem;
    padding: 2rem;
    background: white;
}
.title::before{
   
}
.title{
    text-align: center;
    padding: 1rem;
}
input{
    padding: 5px;

}

.emailWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}
.emailText{
    display: flex;
    align-items: center;
    margin-right: 0.50rem;
}
.emailText::after{
    content:" *";
    color:red;
}
.passwordWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}
.passwordText{
    display: flex;
    align-items: center;
    margin-right: 0.50rem;
}
.passwordText::after{
    content:" *";
    color:red;
}
.checkbox{
    padding: 0.5rem 0rem;
}
.registerBtn{
    margin: 1rem 0rem;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

@media (min-width: 768px) {}