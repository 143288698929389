
.staticPage001.section{
    color:red;
}
.sub-section-comb{
    position: relative;
    background-color: blue;

}
.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;

}
.text-wrapper{

    padding: 1.5rem;
    font-size: 12px;

}

.img-wrapper {
display: flex;
align-items: center;
justify-content: center;
    background-color: brown;
}
.img-wrapper img{
    width: 100vw;
}
.text-content-title{

padding: 1rem;
text-align: center;
}

@media (min-width:768px){

    .wrapper{

        display: flex;
        flex-direction: row;

    }
    .text-wrapper{

        width: 50%;

        font-size: 16px;
    
    }
    
    .img-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: brown;
        }
        .img-wrapper img{
            width: 100%;
        }



}
