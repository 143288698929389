.productDetail-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100vw;

}





@media (min-width: 768px){
    .productDetail-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
            
    }
    .picturesGallery-wrapper{
        justify-content: flex-end;
    }
    .productData-wrapper{
        padding: 2rem;

    
    }
}