.productData-wrapper{
    width: 100vw;
    padding: 1em 0em 1em 2em;
}


@media (min-width: 768px){

   
   
    
}