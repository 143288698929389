    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333;
        color: white;
        padding: 10px 20px;
    }

    .navbar-brand a {
        color: white;
        text-decoration: none;
        font-size: 24px;
    }

    .navbar-menu {
        display: flex;
        gap: 20px;
    }

    .navbar-menu a {
        color: white;
        text-decoration: none;
        font-size: 18px;
        padding: 5px;
    }

    .navbar-menu a.active {
        background-color: aliceblue;
        color: #000;
        border-radius: 5px;
    }

    .navbar-menu a:hover {
        background-color: lightgray;
        color: gray;
        border-radius: 5px;

    }

    .navbar-burger {
        display: none;
        cursor: pointer;
    }

    .selectedLanguage {
        background-color: rgb(26, 23, 180);
        color: red !important;
    }



    /* Responsive Styles */
    @media screen and (max-width: 768px) {
        .navbar-menu {
            display: none;
            flex-direction: column;
            gap: 10px;
            position: absolute;
            top: 60px;
            right: 20px;
            background-color: #333;
            padding: 10px;
            border-radius: 5px;
        }

        .navbar-menu.active {
            display: flex;
            z-index: 1;
        }

        .navbar-menu a.active {
            color: red;
        }

        .navbar-burger {
            display: block;
        }

        .selectedLanguage {
            background-color: rgb(26, 23, 180);
            color: red !important;
        }

    }


    .navbar-burger i {
        font-size: 24px;
    }