.category-Products{
    width: 100vw;
    display: flex;
    flex-direction: column;



}
.title-category{
    width:100vw;
    background-color: red;
    text-align: center;
    padding: 0.5rem;


}
.product{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

@media (min-width: 768px){

    .product{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 1rem;
    }
}