.catalogProducts{
    position: relative;
    width: 100vw;
    height: 100%;
}
.catalogProducts-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}
.productList-wrapper{
    text-align: center;
    width: 60%;
    background-color: blanchedalmond;
    padding: 1rem;
    border: 1px solid var(--principal);
}
.name-ProductList{}
.link-productList{}

@media (min-width: 768px){
    .catalogProducts-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .productList-wrapper{
        border: 1px solid var(--principal);
        width: 15%;

    }
    
}