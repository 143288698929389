.productDetail-wrapper .finishSelector .dropdown {
    position: relative;
    display: inline-block;
}

.productDetail-wrapper .finishSelector .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .productDetail-wrapper .finishSelector .dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
.productDetail-wrapper .finishSelector .dropdown .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
.finishSelector .dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.finishSelector .dropdown:hover .dropdown-content {
  display: block;
}

@media (min-width: 768px){}