.userProfile{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;

}

.userProfile .wrapper-addresses{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*.title-wraper-userData*/
.wrapperSection-userProfile{
display: flex;
flex-direction: column;
padding: 1rem 0rem 1rem 0rem;
}




.userProfile .textTitle-userData,
.changPassword .title-wraper-changePassword{
    margin-left: 1rem;
}

.userProfile .profileData form,
.userProfile .changPassword form{
    padding: 1em 0rem 1rem 0rem;
    display: flex;
    flex-direction: column;
}
.userProfile .addressData,
.userProfile .profileData form div,
.userProfile .changPassword form div
{
     padding: 0rem 1rem 0rem 1rem;
}

.userProfile .wrapper-addresses > div {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;

}

.userProfile .profileData form button,
.userProfile .changPassword form button{
    height: 2.5rem;
  border: none;
  width: 90vw;
  margin: 0.5rem auto;
}

.userProfile .profileData form div > div,
.userProfile .changPassword form div > div{
    display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  align-items: center;
}

.userProfile .userData-wrapper,
.userProfile .address-wrapper{
    display: flex;
    flex-direction: column;

}

.title-wraper-section{


}



@media (min-width: 768px){

    .wrapper-content{
width: 100vw;
    background-color: aqua;
    }
    
    .userData-wrapper{
        display: flex;
        flex-direction: row;
        background-color: lightblue;
    }
    .address-wrapper{
        display: flex;
        flex-direction: row;
        background-color: rgb(0, 255, 42);
    }
    .address-wrapper{
        display: flex;
        flex-direction: row;
        background-color: rgb(47, 0, 255);
    }
}