
.heroSection {
    position: relative;
    width: 100vw;
    height: 100vh;
    padding: 0;
}
.heroSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../images/hero/Imatge_joieria_artesanal.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    filter: brightness(60%);
}

.hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: "Monserrat", sans-serif;
    color: rgb(255, 251, 251);
    
}
.hero-title {
    font-size: 2.5rem;
    font-weight: 600;
}

.hero-subtitle {
    font-size: 1.5rem;
    font-weight: 200;
    padding: 0.2rem;

}
.hero-text {
    font-size: 1rem;
    font-weight: 200;
}

.hero-btn {
    background-color: #ae2d59;
    color: white;
    border-radius: 2rem;
    border: 1px solid #cb376a;
    margin-top: 2rem;
    padding: 0.9375rem 1.875rem;
    font-family: "Monserrat", sans-serif;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 200;
    cursor: pointer;
}
.hero-btn:hover {
    background-color: #cb376a;
    border: 1px solid #db7598;
}

@media (max-width:768px){
    .heroSection {
        /*position: relative;*/
        height: 50vh;
        
    }

    .hero-title {
        font-size: 2em;
        font-weight: 600;
    }
    
    .hero-subtitle {
        font-size: 1rem;
        font-weight: 200;
        padding: 0.2rem;
    
    }
    .hero-text {
        font-size: 0.75rem;
        font-weight: 200;
    }
}