.serviceHandmadeJewelry {
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100vw;
    gap: 1rem;



}
.serviceContent{

}
.dataService {
    width:85%;
    margin: 2rem;
    color: gray;
   }

.dataService *{

margin-top: 1rem;

}
p:last-child{
    font-weight: 400;
}

.descriptionService{
    font-size: 16px;

}
p{
    font-size: 13px;
}

.imageService{
    background-color: black;
    width: 100%;
    height: 260px;
    padding: 1rem;
}
.galleryImages{
    display: flex;
    gap: 0.6rem;
    height: 300px;
    justify-content: center;
    flex-wrap: wrap;
    width: 94vw;
    margin-bottom: 1rem;
    }

.image{
    background-color: rgb(80, 97, 247);
    width: 36vw;
    
    padding: 1rem;
    border-radius: 10px;
}

@media (min-width: 768px){
    .serviceHandmadeJewelry {
        margin-top: 2rem;
    }
.serviceContent{
    display: flex;
    align-items: center;
    

}

       .dataService {
        width:100%;

       }
       .dataService *{

        margin-top: 0rem;
        
        }
       .dataService *:not(:first-child){

        margin-top: 1rem;
        
        }
        .galleryImages{

            width: 90%;
            flex-wrap: nowrap;
            }
}