.form-wrapper{
    width:80%;
    display: flex;
    flex-direction: column;
    border: 1px solid blue;
    margin: 2rem 2rem;
    padding: 1rem 1rem;
}

.contact-form *{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.25rem;
}

@media (min-width: 768px) {

    .form-wrapper{
        width:50%;

    }


}