.login-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: aqua;
}
.login-form-wrapper{
        width: 70%;
        border: 1px solid rgb(14, 41, 32);
        border-radius: 1rem;
        padding: 2rem;
        background: white;
}
.title-login{
    text-align: center;
    padding: 1rem;
}
input{
    width: 100%;
    padding: 0.5rem;

}

.email-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}
.email-text{
    display: flex;
    align-items: center;
    margin-right: 0.50rem;
}
.email-text::after{
    content:" *";
    color:red;
}
.password-wrapper{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
}
.password-text{
    display: flex;
    align-items: center;
    margin-right: 0.50rem;
}
.password-text::after{
    content:" *";
    color:red;
}
.remember-login{
    display: flex;
width: 100%;
}
.remember-text{
    width: 100%;
    display: inline-flex;
    align-items: center;
}
.form-check-input{
    display: flex;
}
.olvido-password{
    text-align: center;
}
.btn-sign-in{
    margin: 1rem 0rem;
    padding: 10px;
    width: 100%;
    cursor: pointer;
  

}
.link-register{
    text-align: center;
}

@media (min-width: 768px) {
    .login-form-wrapper{
        max-width: 40%;
    }

}