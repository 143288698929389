.galeria {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
  
  .imagen-grande {
    width: 90vw;
    height: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .imagen-grande img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .miniaturas {
    width: 80vw;
    overflow-x: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .miniaturas-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  
  .miniatura {
    min-width: 100px;
    height: 100px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .miniatura img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    .galeria {display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
      }
      .miniaturas {
        width: 390px;
      }
      .imagen-grande {
        width: 20vw;
        height: 100%;
        margin-bottom: 20px;
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;
      }
  }