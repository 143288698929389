.banner-shop{
    position: relative;
    width: 95vw;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
}

.cntBreadCrumbs *{
    text-decoration: none;
    color: gray;
   
    
}

.cntBreadCrumbs {
    text-decoration: none;
    color: gray;
    
    
    
}

.select-filter{
color:gray;
}

@media (min-width: 768px){
    .banner-shop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
}