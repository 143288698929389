    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #333;
        color: white;
        padding: 10px 20px;
    }

    .navbar-brand a {
        color: white;
        text-decoration: none;
        font-size: 24px;
    }

    .navbar-cartshop {
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: #333;
        padding: 10px;
        border-radius: 5px;
    }

    .navbar-cartshop a {
        color: white;
        text-decoration: none;
        font-size: 18px;
        padding: 5px;
    }

    .navbar-cartshop a.active {
        background-color: aliceblue;
        color: #000;
        border-radius: 5px;
    }

    .navbar-cartshop a:hover {
        background-color: lightgray;
        color: gray;
        border-radius: 5px;

    }

    .navbar-cart {
        display: none;
        cursor: pointer;
    }


    .imgProduct-cartShop{
        width: 25%;
    }

    .titleCart{}

    .productCartShop{
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.5rem;
        gap: 0.5rem;
        border-bottom: 0.1rem solid white;
    }
    
    .dataProduct{
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
    /*.dataProduct *{
        margin: 0.25rem;
    }*/


    .quantity-wrapper{
        display: flex;
        flex-direction: row;
        border: 0.1rem solid white;
        border-radius: 1rem;
        align-items: center;
    }
    .btnRemoveProduct{
        color:gray;
    }
    .unitProduct{
        background-color: white;
        color:#333;
        padding: 0.25rem;
    }
    .btnAddProduct
    .totalQuantity{
        align-items: flex-end;
        width: 45%;
    }
    .navbar-cartshop-button{}
    .cartShop-button{}



    /* Responsive Styles */
    @media screen and (max-width: 768px) {
        .navbar-cartshop {
            display: none;
            flex-direction: column;
            gap: 10px;
            position: absolute;
            top: 60px;
            right: 20px;
            background-color: #333;
            padding: 10px;
            border-radius: 5px;
        }

        .navbar-cartshop.active {
            display: flex;
            z-index: 1;
            width: 84vw;
            height: 80vh;
            right: initial;
        }

        .navbar-cartshop a.active {
            color: red;
        }

        .navbar-cart {
            display: block;
        }

    }


    .navbar-cart i {
        font-size: 24px;
    }