.contactData-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 1rem;
    width: 85%;
}






@media (min-width: 768px) {

    .contactData-wrapper{
        width:50%;


    }
}