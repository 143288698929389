:root{
    --principal: hsl(0, 79%, 41%);
}

.featuredsSection{
    position: relative;
    width: 100vw;
    height: 100%;
    background-color: white;
}

.featured-title{
    text-align: center;
    padding: 0.5rem;
}

.featureds-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;


}

.featured-content{
    border: 1px solid var(--principal);
    text-align: center;
    width: 60%;
    border-radius: 20px 0px;
    overflow: hidden;
}
.featured-picture{
  width: 100%;
}
.text-product{
    padding: 0.5rem;
}
.shopping-cart-btn{
    background-color: #ae2d59;
    color: white;
    border-radius: 2rem;
    border: 1px solid #cb376a;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-family: "Monserrat", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
}
.shopping-cart-btn:hover {
    background-color: #cb376a;
    border: 1px solid #db7598;
}


@media (min-width: 768px) {
    .featureds-container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .featured-content{
        border: 1px solid var(--principal);
        text-align: center;
        width: 15%;
        border-radius: 20px 0px;
    }
    

}
